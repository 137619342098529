import React, {FC} from 'react';
import {Text, View, Icon, COLOR, SPACE} from '@lookiero/aurora';
import {useIntl} from 'react-intl';

import {style} from './InAppBrowserBanner.styles';
import {TEXT} from './InAppBrowserBanner.definition';

const InAppBrowserBanner: FC = () => {
  const {formatMessage} = useIntl();

  return (
    <View testID="banner" style={style.inAppBrowserBanner}>
      <Icon name="truck" color={COLOR.BASE} marginRight={SPACE.SPACE_3} />

      <Text color={COLOR.BASE} detailBold level={1}>
        {formatMessage(TEXT.BANNER_TEXT)}
      </Text>
    </View>
  );
};

export {InAppBrowserBanner};
