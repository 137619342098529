import {MyLookieroRepository} from '@domain/model/Account/MyLookiero/MyLookieroRepository';
import {CalendarDates} from '@domain/model/Account/MyLookiero/domain/model';

import {executeQuery, executeMutation} from '@infra/api/graphQL/client';
import {Queries} from '@infra/api/graphQL/domain/model/MyLookiero/queries';
import {Mutations} from '@infra/api/graphQL/domain/model/MyLookiero/mutations';

interface CalendarResponse {
  unavailableDates: [string];
  minDate: string;
  maxDate: string;
}

const updateNextOrderDate = async (nextDate: string): Promise<void> => {
  return executeMutation(Mutations.CHANGE_NEXT_BOX_DATE, {date: nextDate});
};

const getCalendarDates = async (country: string): Promise<CalendarDates> => {
  try {
    const {
      calendar: {unavailableDates, minDate, maxDate},
    } = await executeQuery<Record<string, CalendarResponse>>(Queries.CALENDAR_DATES, {country});

    return Promise.resolve({
      unavailableDates,
      minDate,
      maxDate,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ApiMyLookieroRepository: MyLookieroRepository = {updateNextOrderDate, getCalendarDates};
