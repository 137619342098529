import {AuthApplication} from '@application/Auth';
import {LocationApplication} from '@application/Site';

import {DEVICE} from './_types';

const MATCH_MOBILE = /^Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i;

const getScope = async (): Promise<string> => {
  const store = await LocationApplication.getStore();
  const authorization = await AuthApplication.isLoggedIn();

  const userAgent = navigator.userAgent.toLowerCase();
  const mobile = Boolean(userAgent.match(MATCH_MOBILE));

  return `store=${store}&registered=${authorization}&device=${mobile ? DEVICE.MOBILE : DEVICE.DESKTOP}`;
};

export {getScope};
