import React from 'react';
import LookieroApp, {configurePayments} from './src/ui/app/App';
import {configurePromoCodes} from '@modules/promoCodes/config';
import {enableLegacyWebImplementation} from 'react-native-gesture-handler';

import {Platform} from 'react-native';
import {activateKeepAwake} from 'expo-keep-awake';
import {registerRootComponent} from 'expo';

/**
 * This is required for the Slider (checkout's new implementation) to properly work within a ScrollView.
 */
enableLegacyWebImplementation(true);

export default function App(): JSX.Element {
  return <LookieroApp />;
}

if (__DEV__) {
  activateKeepAwake();
}

configurePayments();
configurePromoCodes();

if ('web' === Platform.OS) {
  const rootElement = document.getElementById('root');

  if (rootElement) {
    registerRootComponent(App);
  }
}
