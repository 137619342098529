import {ExoticComponent, LazyExoticComponent} from 'react';
import {
  Account,
  Login,
  MyLookiero,
  ResetPassword,
  Referrals,
  Quiz,
  Shop,
  PublicReturnOrder,
  ProfileMenu,
  Checkout,
  MyPlan,
  StyleProfile,
  QuizRegister,
  ReferralsSummary,
} from './routes.components';

const BASENAME = '/user';

enum Routes {
  LOGIN = 'LOGIN',
  QUIZ_HOME = 'QUIZ_HOME',
  QUIZ = 'QUIZ',
  QUIZ_REGISTER = 'QUIZ_REGISTER',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  REFERRALS_SUMMARY = 'REFERRALS_SUMMARY',
  RETURN_ORDER = 'RETURN_ORDER',
  HOME = 'HOME',
  REFERRALS = 'REFERRALS',
  STYLE_PROFILE = 'STYLE_PROFILE',
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_MY_PLAN = 'ACCOUNT_MY_PLAN',
  PROFILE_MENU = 'PROFILE_MENU',
  SHOP = 'SHOP',
  CHECKOUT = 'CHECKOUT',
  // Native Routes
  INBOX = 'INBOX',
  INTRO = 'INTRO',
  NEWSFEED = 'NEWSFEED',
  ONBOARDING = 'ONBOARDING',
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
  LOOK_AND_LIKE = 'LOOK_AND_LIKE',
}

enum Path {
  LOGIN = '/login',
  QUIZ_HOME = '/quiz',
  QUIZ = '/quiz/*',
  QUIZ_REGISTER = '/quiz/register',
  RECOVER_PASSWORD = '/recover-password',
  RETURN_ORDER = '/my-lk-return/shipments/:serviceId',
  HOME = '/my-lk',
  REFERRALS = '/referrals',
  REFERRALS_SUMMARY = '/referrals/summary',
  STYLE_PROFILE_HOME = '/style-profile',
  STYLE_PROFILE = '/style-profile/*',
  ACCOUNT = '/account',
  ACCOUNT_MY_PLAN = '/account/my-plan',
  PROFILE_MENU = '/profile',
  SHOP = '/shop/*',
  SHOP_HOME = '/shop',
  SHOP_ORDERS = '/shop/orders',
  CHECKOUT = '/checkout/*',
  CHECKOUT_HOME = '/checkout',
  // Native Paths
  INBOX = '/inbox',
  INTRO = '/intro',
  NEWSFEED = '/news',
  ONBOARDING = '/onboarding',
  SOCIAL_LOGIN = '/login/social',
  LOOK_AND_LIKE = '/look-and-like',
}

export interface Route {
  readonly path: Path;
  readonly exact?: boolean;
  readonly authorization: boolean;
  readonly redirect?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly component: LazyExoticComponent<any> | ExoticComponent<any> | ((props: any) => JSX.Element | null);
}

export type RoutesConfig = Record<Routes, Route>;

export type NativeRoutes =
  | Routes.INBOX
  | Routes.INTRO
  | Routes.NEWSFEED
  | Routes.ONBOARDING
  | Routes.SOCIAL_LOGIN
  | Routes.LOOK_AND_LIKE;

const COMMON_ROUTES: Omit<RoutesConfig, NativeRoutes> = {
  // Public
  [Routes.LOGIN]: {path: Path.LOGIN, exact: true, component: Login, authorization: false, redirect: true},
  [Routes.QUIZ_HOME]: {path: Path.QUIZ_HOME, component: Quiz, authorization: false, redirect: true},
  [Routes.QUIZ]: {path: Path.QUIZ, component: Quiz, authorization: false, redirect: true},
  [Routes.QUIZ_REGISTER]: {path: Path.QUIZ_REGISTER, component: QuizRegister, authorization: false, redirect: true},
  [Routes.RECOVER_PASSWORD]: {
    path: Path.RECOVER_PASSWORD,
    exact: true,
    component: ResetPassword,
    authorization: false,
    redirect: true,
  },
  [Routes.RETURN_ORDER]: {
    path: Path.RETURN_ORDER,
    exact: true,
    component: PublicReturnOrder,
    authorization: false,
    redirect: false,
  },
  // Private
  [Routes.HOME]: {
    path: Path.HOME,
    exact: true,
    component: MyLookiero,
    authorization: true,
    redirect: true,
  },
  [Routes.REFERRALS]: {path: Path.REFERRALS, exact: true, component: Referrals, authorization: true, redirect: true},
  [Routes.REFERRALS_SUMMARY]: {
    path: Path.REFERRALS_SUMMARY,
    exact: true,
    component: ReferralsSummary,
    authorization: true,
    redirect: true,
  },
  [Routes.STYLE_PROFILE]: {path: Path.STYLE_PROFILE, component: StyleProfile, authorization: true, redirect: true},
  [Routes.ACCOUNT]: {
    path: Path.ACCOUNT,
    exact: true,
    component: Account,
    authorization: true,
    redirect: true,
  },
  [Routes.PROFILE_MENU]: {
    path: Path.PROFILE_MENU,
    exact: true,
    component: ProfileMenu,
    authorization: true,
    redirect: true,
  },
  [Routes.SHOP]: {path: Path.SHOP, exact: true, component: Shop, authorization: true, redirect: true},
  [Routes.CHECKOUT]: {
    path: Path.CHECKOUT,
    exact: true,
    component: Checkout,
    authorization: true,
    redirect: true,
  },
  [Routes.ACCOUNT_MY_PLAN]: {
    path: Path.ACCOUNT_MY_PLAN,
    exact: true,
    component: MyPlan,
    authorization: true,
    redirect: true,
  },
};

export {COMMON_ROUTES, BASENAME, Path, Routes};
