import {COUNTRY, LANGUAGE} from '@infra/domain/model/Site/Site.definition';

export type Country = (typeof COUNTRY)[keyof typeof COUNTRY];
export type Language = (typeof LANGUAGE)[keyof typeof LANGUAGE];

export interface Locale {
  country: Country;
  language: Language;
}

export {COUNTRY, LANGUAGE};
